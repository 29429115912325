<template lang="pug">
  v-container
    v-row
      v-col(cols="12" lg="4" md="4")
        v-card
          v-toolbar(
            :color="editMode ? 'yellow darken-3' : 'primary'"
            dark
            dense
            flat
          )
            span {{ editMode ? 'Edit' : 'Create' }}
          v-container
            create-duck-egg-collection(
              :default-data.sync="editDuckEggCollection"
            )
      v-col(cols="12" lg="8" md="8")
        v-card
          v-toolbar(
            color="primary"
            dark
            dense
            flat
          )
            span List
          v-container
            table-duck-egg-collection(
              @edit="item => { editDuckEggCollection = item }"
            )
</template>
<script>
export default {
  name: 'DuckEggCollection',
  components: {
    createDuckEggCollection: () => import('./Create'),
    tableDuckEggCollection: () => import('./Table'),
  },
  data () {
    return {
      editDuckEggCollection: {},
    }
  },
  computed: {
    editMode () {
      const { date, ...data } = this.editDuckEggCollection
      return !this.$objectEmpty(data)
    },
  },
}
</script>